import React, { FC, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Icon, LoadingSpinner } from './Generic'
import { observer } from 'mobx-react'
import { StoreContext } from './App'
import { devLog } from '@/methods/devLog'
import getUserAgent from '@/methods/getUserAgent'

interface ButtonProps {
  bgColor?: string
  color?: string
  borderColor?: string
  bgColorHover?: string
  colorHover?: string
  borderColorHover?: string
  bgColorDisabled?: string
  colorDisabled?: string
  borderColorDisabled?: string
  fontSize?: string
  padding?: string
}

const Button = styled.button<ButtonProps>`
  padding: ${(props) => props.padding || '12px 16px'};
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  outline: none;
  cursor: pointer;
  border-radius: 100px;
  font-size: ${(props) => props.fontSize || '14px'};

  margin: 0 20px 0 0;
  background-color: ${(props) => props.bgColor || '#2a0062'};
  color: ${(props) => props.color || '#fff'};
  border: 1px solid ${(props) => props.borderColor || '#2a0062'};

  &:hover {
    background-color: ${(props) => props.bgColorHover || '#fff'};
    color: ${(props) => props.colorHover || '#2a0062'};
    border: 1px solid ${(props) => props.borderColorHover || '#2a0062'};
  }

  &:disabled {
    background-color: ${(props) => props.bgColorDisabled || '#ccc'};
    color: ${(props) => props.colorDisabled || '#fff'};
    border: 1px solid ${(props) => props.borderColorDisabled || '#ccc'};
    cursor: not-allowed;
  }

  @media (max-width: 980px) {
    min-width: 44px;
    padding: 0;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    span {
      display: none;
    }
  }

  div {
    margin: -12px 0;
  }
`

interface DownloadDocumentButtonProps {
  blobDoc?: Blob
  onClick?: () => void
}

export const DownloadDocumentButton: FC<DownloadDocumentButtonProps> = observer(
  ({ blobDoc, onClick }) => {
    const store = useContext(StoreContext)
    const { theme } = store.InterfaceState
    const { signDocumentPage: trans } = store.TranslationsState.translations
    const UserAgent = getUserAgent()

    const [isLoading, setIsLoading] = React.useState(false)

    const downloadFile = async () => {
      if (isLoading) return

      if (UserAgent.browser === 'safari' || UserAgent.system === 'ios') {
        if (!blobDoc) return

        setIsLoading(true)
        const url = window.URL.createObjectURL(blobDoc)
        const a = document.createElement('a')
        a.href = url
        a.target = '_blank'
        a.rel = 'noopener noreferrer'
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        a.parentNode.removeChild(a)

        setIsLoading(false)

        return
      }

      try {
        setIsLoading(true)

        const fileURL = URL.createObjectURL(blobDoc)

        if (fileURL) {
          window.open(fileURL, '_blank', 'noopener,noreferrer')
        }
      } catch (error) {
        devLog(error)
      }

      setIsLoading(false)
    }

    return (
      <Button
        onClick={onClick ? onClick : downloadFile}
        {...theme.downloadDocumentButton}
        padding={theme.button.padding}
        fontSize={theme.button.fontSize}
      >
        {isLoading ? (
          <LoadingSpinner
            width="16px"
            {...theme.loadingSpinner}
            padding="0 0"
            thickness={2}
          />
        ) : (
          <>
            <span>{trans.downloadButton}</span>
            <Icon
              color={'currentColor'}
              colorHover={'currentColor'}
              type="download"
              size="24px"
            />
          </>
        )}
      </Button>
    )
  }
)
