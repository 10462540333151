import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { SignProgress } from '@components/SignProgress/SignProgress'
import { Button, Icon, LoadingSpinner, PdfViewer } from '@components/Generic'
import { observer } from 'mobx-react'
import { StoreContext } from '../App'

import {
  ButtonText,
  CheckboxForm,
  ZoomControlWrap,
} from '@styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentToRight,
} from '@styles/layout.styles'
import { getViewerHeight } from '@styles/utils'
import { DownloadDocumentButton } from '@/components/DownloadDocumentButton'
import { actionReport } from '@/methods/actionReport'
import { axiosInstance } from '@/methods/axiosConfig'
import { Document } from '@/components/SignDocument/types'
import { devLog } from '@/methods/devLog'
import { hideDownloadButton } from '@/methods/hideDownloadButton'

interface FetchedDocData {
  id: string
  url: string
  name: string
}

interface SignDocumentSingleProps {
  signed?: boolean
  setSigned?: (value: boolean) => void
  docs?: Document[]
  btnLoading?: boolean
  setContainerHeight?: (value: string) => void
  handleSign: (docId: string) => Promise<boolean>
  trans: {
    header: string
    checkboxLabel: string
    proceed: string
  }
}

export const SignDocumentMultiple: FC<SignDocumentSingleProps> = observer(
  ({
    signed,
    setSigned,
    docs,
    btnLoading,
    setContainerHeight,
    handleSign,
    trans,
  }) => {
    const store = useContext(StoreContext)
    const { screenSize } = store.AppState
    const { theme } = store.InterfaceState
    const { context, currentPageIndex } = store.ScenarioState
    const documentNeedsScrolling = context?.forceScrollingBeforeDocumentAccept

    const [documentScrolled, setDocumentScrolled] = useState(
      !documentNeedsScrolling
    )

    const zoomButtonProps = {
      ...theme.pdfViewer.zoomButton,
      borderRadius: '50%',
      width: '44px',
      height: '44px',
      widthMobile: '44px',
      heightMobile: '44px',
      paddingMobile: '0px',
      padding: '0px',
    }
    const [shouldZoomIn, setShouldZoomIn] = useState(false)
    const [shouldZoomOut, setShouldZoomOut] = useState(false)

    const footerRef = useRef(null)
    const [headerOffsetHeight, setHeaderOffsetHeight] = useState(0)
    const [footerOffsetHeight, setFooterOffsetHeight] = useState(0)

    useEffect(() => {
      if (footerRef?.current) {
        setFooterOffsetHeight(footerRef.current.offsetHeight)
      }
    }, [footerRef])

    const [mobileVersion, setMobileVersion] = useState(false)
    const downloadButtonDisabled = hideDownloadButton()

    useEffect(() => {
      if (screenSize.width && screenSize.width <= 980) {
        setMobileVersion(true)
        setContainerHeight(
          `calc(${window.innerHeight}px - ${footerOffsetHeight}px)`
        )
      }
    }, [screenSize])

    const handleCheckboxChange = () => {
      actionReport({
        type: 'event.onboarding-web.agreement.AGREEMENT_CONFIRMED',
        payload: {},
      })
      setSigned(!signed)
    }

    const [currentDocIndex, setCurrentDocIndex] = useState(0)
    const [currentDoc, setCurrentDoc] = useState<FetchedDocData>()

    const getDocUrl = useCallback(async () => {
      console.log('GETTING DOC URL', currentDocIndex, docs)
      const doc = docs[currentDocIndex]
      const { data } = await axiosInstance.get<{ signedUrl: string }>(
        `/electronic-id/pdf-document-presigned`,
        {
          withCredentials: true,
          params: {
            fileId: doc.fileId,
          },
        }
      )
      console.log(data)

      setCurrentDoc({
        id: doc.fileId,
        name: doc.name,
        url: data.signedUrl,
      })
    }, [currentDocIndex, docs])

    useEffect(() => {
      void getDocUrl()
    }, [currentDocIndex, docs])

    const handleNextDocument = async () => {
      const doc = docs[currentDocIndex]

      try {
        const isSuccess = await handleSign(doc.fileId)

        if (!isSuccess) {
          return
        }

        if (currentDocIndex < docs.length - 1) {
          setCurrentDocIndex(currentDocIndex + 1)
          setSigned(false)
          setCheckboxKey(checkboxKey + 1)

          if (documentNeedsScrolling) {
            setDocumentScrolled(false)
          }
        } else {
          store.ScenarioState.setCurrentPage(currentPageIndex + 1)
        }
      } catch (error) {
        devLog(error)
      }
    }

    // checkbox state fix
    const [checkboxKey, setCheckboxKey] = useState(0)

    // audit logs
    useEffect(() => {
      if (signed === true) {
        actionReport({
          type: 'event.onboarding-web.agreement.AGREEMENT_CONFIRMED',
          payload: {
            documentId: currentDoc?.id,
          },
        })
      }
    }, [signed])

    useEffect(() => {
      if (documentScrolled === true && documentNeedsScrolling) {
        actionReport({
          type: 'event.onboarding-web.agreement.AGREEMENT_SCROLLED_TO_THE_END',
          payload: {
            documentId: currentDoc?.id,
          },
        })
      }
    }, [documentScrolled])

    const [pdfForDownload, setPdfForDownload] = useState<Blob>(null)

    const fetchBlob = async () => {
      if (currentDoc?.id) {
        try {
          const res = await axiosInstance.get<{ signedUrl: string }>(
            `/electronic-id/pdf-document-presigned`,
            {
              withCredentials: true,
              params: {
                fileId: currentDoc?.id,
              },
            }
          )

          const { data } = await axiosInstance.get<Blob>(res.data?.signedUrl, {
            responseType: 'blob',
            withCredentials: false,
          })

          if (data) {
            setPdfForDownload(data)
          }
        } catch (error) {
          devLog('Error downloading file', error)
        }
      }
    }

    useEffect(() => {
      void fetchBlob()
    }, [currentDoc])

    return (
      <>
        {!currentDoc?.url?.length ? (
          <LoadingSpinner
            width="68px"
            padding="100px 0"
            {...theme.loadingSpinner}
          />
        ) : (
          <>
            <SignProgress
              setContainerHeight={setHeaderOffsetHeight}
              currentPage={currentDocIndex + 1}
              documentList={docs}
            >
              <ZoomControlWrap>
                <Button
                  onClick={() => setShouldZoomOut(true)}
                  {...zoomButtonProps}
                >
                  <Icon
                    {...theme.pdfViewer.zoomButton}
                    type="zoom-out"
                    size="30px"
                  />
                </Button>

                <Button
                  onClick={() => setShouldZoomIn(true)}
                  {...zoomButtonProps}
                >
                  <Icon
                    {...theme.pdfViewer.zoomButton}
                    type="zoom-in"
                    size="30px"
                  />
                </Button>
              </ZoomControlWrap>
            </SignProgress>

            <PdfViewer
              file={currentDoc?.url}
              fileId={currentDoc?.id}
              {...theme.pdfViewer}
              viewerWidth="100%"
              containerHeight={
                mobileVersion
                  ? getViewerHeight(
                      screenSize,
                      headerOffsetHeight + 160,
                      footerOffsetHeight
                    )
                  : getViewerHeight(screenSize)
              }
              viewerHeight="100%"
              initialScale={1}
              onDocumentLastPage={() => setDocumentScrolled(true)}
              hideZoomControls
              controls={{
                shouldZoomIn,
                setShouldZoomIn,
                shouldZoomOut,
                setShouldZoomOut,
              }}
            />

            <ContentContainerBottomFixed ref={footerRef}>
              <CheckboxForm>
                <input
                  key={'checkbox-' + checkboxKey}
                  type="checkbox"
                  name="sign"
                  value={signed.toString()}
                  id="sign"
                  onChange={handleCheckboxChange}
                />
                <label className="label-text" htmlFor="sign">
                  {trans.checkboxLabel}
                </label>
              </CheckboxForm>
              <ContentToRight>
                {!downloadButtonDisabled && (
                  <DownloadDocumentButton blobDoc={pdfForDownload} />
                )}

                <Button
                  onClick={handleNextDocument}
                  {...theme.button}
                  disabled={!signed || !documentScrolled}
                  minWidth="224px"
                  paddingMobile="14px 40px"
                  id="page-submit"
                >
                  {btnLoading ? (
                    <LoadingSpinner
                      width="16px"
                      {...theme.loadingSpinner}
                      padding="0 0"
                      thickness={2}
                    />
                  ) : (
                    <ButtonText>{trans.proceed}</ButtonText>
                  )}
                </Button>
              </ContentToRight>
            </ContentContainerBottomFixed>
          </>
        )}
      </>
    )
  }
)
